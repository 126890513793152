<template>
  <div class="about">
    <!-- 上海浦东医院 -->
    <!-- 上海浦东医院 -->
    <!-- 上海浦东医院 -->
    <!-- 上海浦东医院 -->
    <!-- 上海浦东医院 -->
    <!-- 上海浦东医院 -->
    <!-- 上海浦东医院 -->
    <!-- 上海浦东医院 -->
    <!-- 上海浦东医院 -->
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div>
        姓名：
        <el-input v-model="queryObj.name" placeholder="请输入姓名" clearable></el-input>
      </div>
      <div>
        <span>状态：</span>
        <el-select v-model="queryObj.useFlag" placeholder="全部" clearable>
          <el-option label="入院" :value="0"></el-option>
          <el-option label="出院" :value="1"></el-option>
        </el-select>
      </div>
      <span>
        <el-button v-if="$has('importPatient')" type="primary" size="mini" icon="el-icon-bottom" @click="plDialog = true">批量导入</el-button>
        <el-button v-if="$has('exportPatientHistory')" type="primary" size="mini" icon="el-icon-top" @click="exportExcel">导出Excel</el-button>
        <el-button v-if="$has('viewPatient')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button v-if="$has('viewPatient')" type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        <el-button v-if="$has('addPatient')" type="warning" size="mini" icon="el-icon-plus" @click="openDialog(0, 0)">新 增</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" height="calc(100% - 40px)">
        <el-table-column prop="areaName" label="区域名称" width="101"></el-table-column>
        <el-table-column prop="organizationName" label="机构名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="gender" label="性别" width="70">
          <template slot-scope="{ row }">
            <el-tag :type="row.gender == 0 ? 'danger' : 'primary'">{{ row.gender == 0 ? '女' : '男' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="birth" label="出生日期" show-overflow-tooltip></el-table-column>
        <el-table-column prop="treatmentNum" label="入院次数 / 测量次数">
          <template slot-scope="{ row }">
            {{ row.hospitalizationNum }} / <i style="font-weight: bold">{{ row.treatmentNum }}</i>
          </template>
        </el-table-column>
        <el-table-column prop="firstDose" label="首次剂量(μSv/h)">
          <template slot-scope="{ row }">
            {{ row.firstDose ? row.firstDose : '首次测量后自动录入' }}
          </template>
        </el-table-column>
        <el-table-column prop="oralDose" label="口服剂量(mCi)"></el-table-column>
        <el-table-column prop="useFlag" label="状态" width="70">
          <template slot-scope="{ row }">
            <el-tag :type="row.useFlag ? 'primary' : 'success'">{{ row.useFlag ? '出院' : '入院' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="admissionDt" label="入院时间" width="160"></el-table-column>
        <el-table-column label="操作" width="210">
          <template slot-scope="{ row }">
            <el-button v-if="$has('getPatientDoserateList')" @click="openDialog(2, row)" type="warning" size="small" icon="el-icon-document" class="operateSty">采集列表</el-button>
            <el-button v-if="$has('editPatient')" @click="openDialog(1, row)" type="primary" size="small" icon="el-icon-edit-outline" class="operateSty">编 辑</el-button>
            <el-button v-if="$has('readmission') && row.useFlag" type="danger" size="small" icon="el-icon-right" @click="onceInhospital(row)" style="margin: 0">再次入院</el-button>
            <el-button v-if="$has('getPatientDoserateList')" type="success" size="small" icon="el-icon-printer" @click="openDialog(3, row)">打 印</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 新增,编辑,模态框 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :width="dialogType == 2 || dialogType == 3 ? '40%' : '30%'" :modal="true" :close-on-click-modal="false" v-dialogDrag center @close="cancles">
        <div class="dialogsC">
          <!-- 新增, 编辑 -->
          <div class="newDialog" v-if="dialogType == 0 || dialogType == 1">
            <input-list ref="editIpt" @setData="getData" iptType="2"></input-list>
            <div>
              <span><i style="color: red">* </i>姓名：</span>
              <el-input placeholder="请输入姓名" v-model="paramsObj.name" clearable></el-input>
            </div>
            <div class="radioSty">
              <span><i style="color: red">* </i>性别：</span>
              <div>
                <el-radio v-model="paramsObj.gender" :label="0">女</el-radio>
                <el-radio v-model="paramsObj.gender" :label="1">男</el-radio>
              </div>
            </div>
            <div class="pickerSty">
              <span><i style="color: red">* </i>出生日期：</span>
              <el-date-picker v-model="paramsObj.birth" type="date" placeholder="选择日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div>
              <span><i style="color: red">* </i>口服剂量(mCi)：</span>
              <el-input :disabled="isUseFlag" placeholder="请输入口服剂量(mCi)" v-model="paramsObj.oralDose" clearable></el-input>
            </div>
          </div>
          <!-- 采集列表 -->
          <div v-else-if="dialogType == 2" class="cjSty">
            <el-table :key="1" :data="cjList" height="100%">
              <el-table-column prop="treatmentNum" label="入院次数 / 测量次数">
                <template slot-scope="{ row }">
                  {{ row.hospitalizationNum }} / <i style="font-weight: bold">{{ row.treatmentNum }}</i>
                </template>
              </el-table-column>
              <el-table-column prop="doserate" label="测量结果(μSv/h)"></el-table-column>
              <el-table-column prop="phaseMbq" label="测量结果(Mbq)"></el-table-column>
              <el-table-column prop="phaseMci" label="测量结果(mCi)"></el-table-column>
              <el-table-column prop="endDt" label="测量时间"></el-table-column>
              <div slot="empty" class="empty">
                <img src="../../public/home/noData.svg" width="200" alt="" />
                <span>暂无数据</span>
              </div>
            </el-table>
          </div>
          <!-- 打印 -->
          <div class="newDialog measureSty" v-else id="printMeasure">
            <div class="measureInfo">
              <div>患者采集列表记录报告单</div>
              <div>
                <span>姓名：{{ measure.name }}</span>
                <span>性别：{{ measure.gender }}</span>
                <span>出生日期：{{ measure.birth }}</span>
                <span>打印时间：{{ measure.printTime }}</span>
              </div>
            </div>
            <el-table :data="printList" height="calc(100% - 40px)">
              <el-table-column prop="treatmentNum" label="测量次数">
                <template slot-scope="{ row }"> 第{{ row.treatmentNum }}次 </template>
              </el-table-column>
              <el-table-column prop="doserate" label="测量结果(μSv/h)"></el-table-column>
              <el-table-column prop="phaseMbq" label="测量结果(Mbq)"></el-table-column>
              <el-table-column prop="phaseMci" label="测量结果(mCi)"></el-table-column>
              <el-table-column prop="endDt" label="测量时间"></el-table-column>
              <div slot="empty" class="empty">
                <img src="../../public/home/noData.svg" width="200" alt="" />
                <span>暂无数据</span>
              </div>
            </el-table>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancles">取 消</el-button>
          <el-button v-if="dialogType == 0 || dialogType == 1" type="primary" @click="confirmClk">确 定</el-button>
          <el-button :key="1" v-if="$has('startMonitor') && dialogType == 2" :disabled="!!measure.useFlag" type="primary" @click="measureClk">测 量</el-button>
          <el-button v-if="dialogType == 3" type="primary" @click="printClk">打 印</el-button>
        </span>
      </el-dialog>
      <el-dialog :title="measureTitle" :visible.sync="measureDialog" width="30%" :modal="true" :close-on-click-modal="false" @close="confirmCancle" v-dialogDrag center>
        <div class="dialogsC">
          <!-- 再次入院 -->
          <div class="newDialog" v-if="isOnce">
            <div>
              <i style="color: red">* </i>
              <span>口服剂量(mCi)：</span>
              <el-input placeholder="请输入口服剂量(mCi)" v-model="inHospital.oralDose" clearable></el-input>
            </div>
          </div>
          <!-- 测量 -->
          <div class="newDialog" v-else>
            <div>
              <span>患者姓名：</span>
              <el-input disabled placeholder="请输入患者姓名" v-model="measure.name" clearable></el-input>
            </div>
            <div class="nowarp">
              <span>剂量当量率(μSv/h)：</span>
              <el-input disabled :placeholder="wsVal" v-model="measure.doserate" clearable></el-input>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmCancle">取 消</el-button>
          <el-button v-if="isOnce" type="primary" @click="confirmOnce">确 定</el-button>
          <el-button v-else :disabled="!measure.doserate" type="primary" @click="beginMeasure">开始测量</el-button>
        </span>
      </el-dialog>
      <!-- 批量导入 -->
      <el-dialog title="批量导入" :visible.sync="plDialog" width="60%" :modal="true" :close-on-click-modal="false" v-dialogDrag center @close="plCancle">
        <div class="plList" v-if="isPl">
          <img src="../../public/home/noData.svg" width="200" alt="" />
          <div class="plText">
            <p>1.请点击下载模板，并按照模板格式填写内容。</p>
            <p>2.填写完后点击导入Excel</p>
          </div>
        </div>
        <div class="dataList" v-else>
          <el-table :data="plTableData[0]" height="100%">
            <el-table-column :prop="item" :label="item" show-overflow-tooltip v-for="item in plTableHead" :key="item.index"></el-table-column>
          </el-table>
        </div>
        <div class="plBtn">
          <el-button type="primary" size="mini" @click="downTemplate">下载模板</el-button>
          <el-upload ref="upload" class="filter-item" action="#" type="primary" :on-change="uploadExcel.bind(null, '')" :show-file-list="false" :file-list="fileList" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" :auto-upload="false">
            <el-button type="warning" size="mini">导入Excel</el-button>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="plCancle">关 闭</el-button>
          <el-button type="primary" @click="plConfirmClk">确 认</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { radPatientSave, radPatientUpdate, radPatientQuery, radPatientChangeUseFlag, radPatientDownloadExcel, radPatientDownloadTemplate, radPatientImport, radPatientDoserateGetPatientDoserateList, deviceQueryWithPage, radPatientDoserateStartMonitor, radPatientDoserateEndMonitor, queryThreshold, radPatientDoserateUpdateWorkState, radPatientReadmission, radPatientDownloadIdCard } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
import { openDevice, closeDevice } from '@/util/idCard'
import { read, utils } from 'xlsx'
import print from 'print-js'
import { timesfun } from '@/util/timeData'

export default {
  components: { inputList, ToTop },
  directives: {
    print,
  },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //数据
      user: this.$store.state.user,
      paramsObj: {}, //新增编辑对象
      dialogVisible: false, //模态框显影
      dialogTitle: '', //模态框标题
      isUseFlag: false, //状态显影
      dialogType: 0, //新增编辑标记
      dialogRow: {}, //当前点击的一条数据
      queryObj: {
        areaId: '',
        organizationId: '',
        name: '',
        useFlag: '',
      },
      inHospital: {
        firstDose: '',
        oralDose: '',
        id: '',
      },
      idCardUrl: 'http://127.0.0.1:19196/',
      cjList: [],
      measure: {
        name: '',
        id: '',
        idNumber: '',
        doserate: '',
        useFlag: '',
        gender: '',
        birth: '',
        printTime: '',
      },
      measureDialog: false,
      measureTitle: '',
      ws: null,
      wsId: '', //设备id
      keepAliveTimer: null, // 心跳定时器
      reconnectTimeOutInterval: null, // 重连定时器
      lastTime: new Date(), // 最后一次连接时间
      connectNum: 1, // ws重连次数
      is_open_socket: false, //websocket连接是否打开,避免重复连接
      is_first_online: false, //是否第一次在线
      is_first_offline: false, //是否第一次离线
      is_active_close_socket: false, //是否主动关闭ws
      baseUrl: '',
      timeMeasure: null,
      wsVal: '剂量当量率',
      isOnce: false, // 再次入院
      queryThreshold: {
        standard: '',
        overTime: '',
        measureTime: '',
      },
      beginMeasureId: '',
      plDialog: false,
      fileList: [],
      isPl: true,
      plTableData: [],
      plTableHead: [],
      fileRow: '',
      printList: [],
      pickerOptions: {
        // 不能选择当前时间以后的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
    }
  },
  methods: {
    // 拿值区域和机构
    getData(msg, iptType) {
      console.log(msg, iptType)
      if (iptType == 1) {
        this.queryObj.areaId = msg[0]
        this.queryObj.organizationId = msg[1]
      } else {
        this.paramsObj.areaId = msg[0]
        this.paramsObj.organizationId = msg[1]
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        ...this.queryObj,
      }
      await radPatientQuery(params)
        .then((data) => {
          this.tableData = data.result.records
          this.totals = data.result.total
        })
        .catch(() => {})
      loading.close()
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.queryObj = {
        areaId: '',
        organizationId: '',
        name: '',
        idCard: '',
        useFlag: '',
      }
      this.$bus.$emit('clickResert', 1)
      this.searchs(1, 10)
    },
    // 取消
    cancles() {
      this.cjList = []
      this.dialogVisible = false
      this.paramsObj = {}
      this.measure = {
        name: '',
        id: '',
        idNumber: '',
        doserate: '',
        useFlag: '',
        gender: '',
        birth: '',
        printTime: '',
      }
      this.printList = []
      this.$bus.$emit('clickResert', 2)
    },
    // 展开模态框 flag: 0新增, 1编辑, 2采集列表,3打印
    openDialog(flag, row) {
      this.dialogType = flag
      if (flag == 0) {
        this.dialogTitle = '新增患者'
        this.isUseFlag = false
      } else if (flag == 1) {
        this.$nextTick(() => {
          // 区域，机构的值回显
          let rs = this.$refs.editIpt
          rs.cgxqy = row.areaId
          rs.cxjgmc().then(() => {
            rs.cjgmc = row.organizationId
            this.paramsObj.organizationId = rs.cjgmc
          })
        })
        this.paramsObj = Object.assign({}, row)
        this.dialogRow = row
        this.dialogTitle = '编辑患者'
        this.isUseFlag = true
      } else if (flag == 2) {
        this.dialogTitle = '采集列表'
        this.cjQuery(row, false)
      } else if (flag == 3) {
        this.dialogTitle = '打印预览（仅打印本次入院期间的测量记录）'
        this.cjQuery(row, false)
      }
      this.dialogVisible = true
    },
    // 打印
    printClk() {
      let s = document.querySelectorAll('#printMeasure .el-table--scrollable-y .el-table__body-wrapper')[0]
      if (s) s.style.height = '100%'
      this.$nextTick(() => {
        print({
          printable: 'printMeasure',
          type: 'html',
          style: '@page {margin:0 10mm};',
          targetStyles: ['*'],
        })
      })
    },
    // 采集列表
    cjQuery(row, flag) {
      let params = {
        pageNo: 1,
        pageSize: 1000,
        patientId: flag ? this.measure.id : row.id,
      }
      // 采集列表
      radPatientDoserateGetPatientDoserateList(params).then((res) => {
        if (res) {
          this.cjList = res.result.records
          let newItem = row.hospitalizationNum
          this.printList = this.cjList.filter((v) => {
            return v.hospitalizationNum == newItem
          })
          if (!flag) {
            this.measure.name = row.name
            this.measure.id = row.id
            this.measure.idNumber = row.idNumber
            this.measure.admissionDt = row.admissionDt
            this.measure.useFlag = row.useFlag
            this.measure.gender = row.gender ? '男' : '女'
            this.measure.birth = row.birth
            this.measure.printTime = timesfun(new Date())
          }
        }
      })
    },
    // 确认
    confirmClk() {
      if (!this.paramsObj.areaId) {
        this.$message.error('区域名称不能为空')
        return
      }
      if (!this.paramsObj.organizationId) {
        this.$message.error('机构名称不能为空')
        return
      }
      if (!this.paramsObj.name) {
        this.$message.error('姓名不能为空')
        return
      }
      if (this.paramsObj.gender === '' || this.paramsObj.gender === undefined) {
        this.$message.error('性别不能为空')
        return
      }
      if (!this.paramsObj.birth) {
        this.$message.error('出生日期不能为空')
        return
      }
      if (!this.paramsObj.oralDose) {
        this.$message.error('口服剂量不能为空')
        return
      }
      if (this.dialogType == 0) {
        radPatientSave(qs.stringify(this.paramsObj)).then((data) => {
          if (data) this.returnState(data)
        })
      } else if (this.dialogType == 1) {
        let { phone, idCard } = this.paramsObj

        if (this.paramsObj.phone && this.paramsObj.phone.split('').includes('*')) {
          this.paramsObj.phone = ''
        }
        if (this.paramsObj.idCard && this.paramsObj.idCard.split('').includes('*')) {
          this.paramsObj.idCard = ''
        }
        radPatientUpdate(qs.stringify(this.paramsObj)).then((data) => {
          if (data) {
            this.returnState(data)
          } else {
            this.paramsObj.phone = phone
            this.paramsObj.idCard = idCard
          }
        })
      }
    },
    // 导出
    exportExcel() {
      let params = {
        pageNo: 1,
        pageSize: 1000000,
        ...this.queryObj,
      }
      radPatientDownloadExcel(params).then((res) => {
        if (res) this.down('患者管理', res)
      })
    },
    // 批量导入
    // 下载
    down(name, res) {
      const blob = new Blob([res])
      var a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = `${name}.xlsx`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    // 模板下载
    downTemplate() {
      radPatientDownloadTemplate().then((res) => {
        if (res) this.down('患者管理模板', res)
      })
    },
    // excel表上传
    uploadExcel(index, file) {
      const fileLimit = file.size / 1024 / 1024 < 2
      if (!fileLimit) {
        this.$message.error('上传文件大小不超过2M！')
        return
      }
      const fileName = file.raw.name
      const fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
      if (fileType != 'xlsx' && fileType != 'xls') {
        this.$message.error('附件格式错误！请上传.xlsx或.xls格式的文件！')
        return
      }
      // 判断上传文件格式
      if (file.raw) {
        this.fileRow = file.raw
        this.readExcel(file)
      } else {
        this.$message.error('请上传附件！')
      }
    },
    // 解析Excel
    readExcel(file) {
      const fileReader = new FileReader()
      fileReader.onload = (ev) => {
        try {
          this.plTableData = []
          this.plTableHead = []
          const data = ev.target.result
          const workbook = read(data, { type: 'binary' })
          // 取对应表生成json表格内容
          workbook.SheetNames.forEach((item) => {
            this.plTableData.push(utils.sheet_to_json(workbook.Sheets[item]))
          })
          // 该算法仅针对表头无合并的情况
          if (this.plTableData[0].length > 0) {
            // 获取excel中数组中对象项数最多的索引，并且将表头提取出来
            for (const key in this.plTableData[0][this.findMaxIndex(this.plTableData[0])]) {
              this.plTableHead.push(key)
            }
            this.isPl = false
          } else {
            this.$message.error('Excel内容为空, 请重新导入!')
          }
        } catch (e) {
          return false
        }
      }
      fileReader.readAsBinaryString(file.raw)
    },
    // 查找数组中对象项数最多的索引
    findMaxIndex(array) {
      const maxIndex = array.reduce((maxIdx, obj, idx) => {
        if (Object.keys(obj).length > Object.keys(array[maxIdx]).length) {
          return idx
        } else {
          return maxIdx
        }
      }, 0)
      return maxIndex
    },
    // 确认导入
    plConfirmClk() {
      if (!this.fileRow) {
        this.$message.error('请上传文件')
        return
      }
      const formData = new FormData()
      formData.append('file', this.fileRow)
      radPatientImport(formData).then((res) => {
        if (res && res.type == 'application/json') {
          this.$message.success('导入成功')
          this.searchs(this.currpage, this.pagesize)
          this.plCancle()
        } else {
          this.$message.error('导入失败, 请点击下载文件查看失败原因')
          this.isPl = true
          this.fileRow = ''
          this.down('导入失败', res)
        }
      })
    },
    // 关闭
    plCancle() {
      this.plDialog = false
      this.isPl = true
      this.fileRow = ''
      this.plTableData = []
      this.plTableHead = []
    },
    // 返回状态
    async returnState(data) {
      await this.$message.success(data.message)
      this.cancles()
      this.searchs(this.currpage, this.pagesize)
    },
    // 再次入院弹窗
    onceInhospital(row) {
      this.isOnce = true
      this.measureTitle = '再次入院'
      this.measureDialog = true
      this.inHospital.id = row.id
    },
    // 再次入院
    confirmOnce() {
      if (!this.inHospital.oralDose) {
        this.$message.error('口服剂量不能为空')
        return
      }
      radPatientReadmission(this.inHospital).then((res) => {
        if (res) {
          this.$message.success(res.message)
          this.measureDialog = false
          this.dialogVisible = false
          this.inHospital = {
            firstDose: '',
            oralDose: '',
            id: '',
          }
          this.searchs(this.currpage, this.pagesize)
        }
      })
    },
    // 再次入院取消
    confirmCancle() {
      this.measureDialog = false
      this.inHospital.firstDose = ''
      this.inHospital.oralDose = ''
    },
    // 测量
    measureClk() {
      this.isOnce = false
      this.measureTitle = '测量'
      // 采集列表无数据
      if (!this.cjList.length) {
        this.measureDialog = true
        return
      }
      let nowTime = new Date().getTime()
      let overTime = this.queryThreshold.overTime * 1000 * 60 * 60 * 24
      // 获取最新的数据时间的项的时间
      let cjEndDt = this.cjList.reduce(function (prev, current) {
        return new Date(prev.endDt) > new Date(current.endDt) ? prev : current
      }).endDt
      let admissionDt = this.measure.admissionDt
      // 当前时间减去采集列表中最后一条的结束时间大于overTime 且 当前时间减去入院时间大于overTime
      if (nowTime - new Date(cjEndDt).getTime() < overTime || nowTime - new Date(admissionDt).getTime() < overTime) {
        this.measureDialog = true
        return
      }
      let time = ((nowTime - new Date(cjEndDt).getTime()) / 1000 / 60 / 60 / 24).toFixed(0)
      this.$confirm(`最近一次测量时间距今已有${time}天,是否再次入院?`, '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        closeOnClickModal: false,
        type: 'warning',
      })
        .then(() => {
          this.measureDialog = true
          this.isOnce = true
          this.inHospital.id = this.measure.id
          this.measureTitle = '再次入院'
        })
        .catch(() => {
          this.measureDialog = true
        })
    },
    // 开始测量
    beginMeasure() {
      let i = this.queryThreshold.measureTime
      const loading = this.$loading({
        lock: true,
        text: '正在测量中,请勿关闭页面......' + i,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.timeMeasure = setInterval(() => {
        i--
        loading.text = '正在测量中,请勿关闭页面......' + i
        if (i === 0) {
          clearInterval(this.timeMeasure)
          this.timeMeasure = null
          // 结束测量
          radPatientDoserateEndMonitor({ patientDoserateId: this.beginMeasureId })
            .then((res) => {
              this.$message.success(res.message)
              loading.close()
              // 更新采集列表数据
              this.cjQuery('', true)
              this.searchs(this.currpage, this.pagesize)
              this.measureDialog = false
              if (res && res.result.doserate < this.queryThreshold.standard) {
                this.$confirm(`当前剂量已符合出院标准(${this.queryThreshold.standard}μSv/h),是否出院?`, '提示', {
                  confirmButtonText: '是',
                  cancelButtonText: '否',
                  closeOnClickModal: false,
                  type: 'warning',
                })
                  .then(() => {
                    radPatientChangeUseFlag({ id: this.measure.id }).then((res1) => {
                      if (res) {
                        this.$message.success('出院成功')
                        this.dialogVisible = false
                        this.searchs(this.currpage, this.pagesize)
                      }
                    })
                  })
                  .catch(() => {})
              }
            })
            .catch(() => {
              loading.close()
            })
        }
      }, 1000)
      // 开始测量
      radPatientDoserateStartMonitor({ patientId: this.measure.id }).then((res) => {
        if (res) {
          this.beginMeasureId = res.result.id
        } else {
          loading.close()
          clearInterval(this.timeMeasure)
          this.timeMeasure = null
        }
      })
    },
    // 设备连接
    connectDev() {
      this.wsVal = '设备连接中......'
      let params = {
        pageNo: 1,
        pageSize: 10,
        areaId: this.queryObj.areaId,
        organizationId: this.queryObj.organizationId,
        type: JSON.parse(sessionStorage.getItem('deviceType')).find((v) => v.code == 2).code,
        useFlag: 0,
      }
      deviceQueryWithPage(params).then((res) => {
        if (res && res.result.records.length) {
          this.wsId = res.result.records[0].id
          radPatientDoserateUpdateWorkState({ deviceId: this.wsId }).then((res) => {})
          this.createSocket(this.wsId)
        } else {
          this.wsVal = '未找到可用的核素药物残留活度监测仪，请先添加设备'
        }
      })
    },
    // 建立连接
    createSocket(deviceId) {
      if ('WebSocket' in window) {
        this.ws = new WebSocket(`${this.baseUrl}?deviceId=${deviceId}`)
        this.ws.onopen = this.websocketonopen
        this.ws.onerror = this.websocketonerror
        this.ws.onmessage = this.websocketonmessage
        this.ws.onclose = this.websocketclose
      } else {
        // 浏览器不支持 WebSocket
        this.$message.error('您的浏览器不支持 WebSocket!')
      }
    },
    // 连接成功后调用
    websocketonopen() {
      if (this.reconnectTimeOutInterval) {
        clearTimeout(this.reconnectTimeOutInterval)
        this.reconnectTimeOutInterval = null
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      this.connectNum = 1
      this.is_open_socket = true
      this.is_first_online = true
      this.is_first_offline = true
      this.is_active_close_socket = false
      this.lastTime = new Date()
      // 心跳机制
      this.keepAlive()
    },
    // 发生错误时调用
    websocketonerror() {
      console.log('连接已出错...')
      this.is_active_close_socket = false
    },
    // 接收消息
    websocketonmessage(e) {
      this.is_first_offline = true
      if (this.is_first_online) {
        this.is_first_online = false
        if (this.reconnectTimeOutInterval) {
          clearTimeout(this.reconnectTimeOutInterval)
          this.reconnectTimeOutInterval = null
        }
        this.connectNum = 1
      }
      this.lastTime = new Date()
      let evalData = eval('(' + e.data + ')').result
      console.log('evalData', evalData)
      this.measure.doserate = evalData
    },
    // 关闭连接时调用
    websocketclose() {
      console.log('关闭连接')
      if (this.reconnectTimeOutInterval) {
        clearTimeout(this.reconnectTimeOutInterval)
        this.reconnectTimeOutInterval = null
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      this.is_open_socket = false
      if (this.connectNum < 6) {
        if (!this.is_active_close_socket) {
          console.log(`服务器连接失败，正尝试第${this.connectNum}次连接`)
          // this.$message.error(`服务器连接失败，正尝试第${this.connectNum}次连接`)
          this.reconnectTimeOutInterval = setTimeout(() => {
            this.createSocket(this.wsId)
          }, 5000)
          this.connectNum += 1
        }
      } else {
        if (!this.is_active_close_socket) {
          this.$message.error('服务器连接失败，请刷新页面重试')
        }
        this.connectNum = 1
      }
      this.is_active_close_socket = false
    },
    // 心跳机制
    keepAlive() {
      console.log('心跳', new Date())
      if (new Date().getTime() - this.lastTime.getTime() > 1000 * 30) {
        if (this.is_first_offline) {
          this.wsVal = '设备连接失败, 请检查设备状态'
          this.measure.doserate = ''
        }
        this.is_first_offline = false
        this.is_first_online = true
      } else {
        if (this.is_first_online && this.is_first_offline) {
          console.log('设备连接中...')
        }
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      this.keepAliveTimer = setTimeout(() => {
        //判断当前webscokt状态
        if (this.ws.readyState) {
          //调用发送方法
          this.ws.send(
            JSON.stringify({
              type: 'Ping',
              t: new Date().getTime(),
            })
          )
          this.keepAlive()
        }
      }, 5000)
    },
    // 清空数据连接
    clearData() {
      if (this.ws && this.ws.readyState) {
        this.ws.close()
      }
      this.ws = null
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      if (this.reconnectTimeOutInterval) {
        clearTimeout(this.reconnectTimeOutInterval)
        this.reconnectTimeOutInterval = null
      }
      this.connectNum = 1
      this.is_open_socket = false
      this.is_active_close_socket = true
    },
    // 浏览器刷新事件
    beforeunloadFn(e) {
      // 开始测量点击出现后才询问是否刷新
      if (this.timeMeasure) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    // 测量超时时间,出院标准,检测时间
    measureOverTime() {
      let params = {
        pageNo: 1,
        pageSize: 100,
        deviceType: 2,
      }
      queryThreshold(params).then((res) => {
        if (res) {
          let rs = res.result.records
          if (rs.length) {
            rs.forEach((item) => {
              if (item.codeType == 'nuclide_overtime') {
                this.queryThreshold.overTime = item.code
              } else if (item.codeType == 'nuclide_discharge_criteria') {
                this.queryThreshold.standard = item.code
              } else if (item.codeType == 'nuclide_measure_time') {
                this.queryThreshold.measureTime = item.code
              }
            })
          }
        }
      })
    },
  },
  mounted() {
    this.searchs(1, 10)
    this.connectDev()
    this.measureOverTime()
    var regExpDomain = /^[a-zA-Z0-9][a-zA-Z0-9\.\-]*\.[a-zA-Z]{2,4}$/
    // true 线上, false 本地
    if (regExpDomain.test(window.location.host)) {
      this.baseUrl = `wss://${location.host}/wss/ws`
    } else {
      let url = process.env.NODE_ENV == 'development' ? '192.168.1.54' : `${location.hostname}`
      this.baseUrl = `ws://${url}:20000/ws`
    }
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  beforeDestroy() {
    this.$store.commit('DEVICEIDS', [])
  },
  destroyed() {
    closeDevice()
    this.clearData()
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.cont {
  width: 100%;
}
.operateSty {
  margin-bottom: 10px;
}
.item {
  margin-top: 10px;
  /deep/.el-badge__content.is-fixed {
    right: 0;
  }
}
.plList {
  padding: 0 10px;
  text-align: center;
}
.plBtn {
  display: flex;
  justify-content: center;
  .filter-item {
    margin-left: 10px;
  }
}
.dataList {
  height: 500px;
}
.cjSty {
  width: 100%;
  height: 500px;
  /deep/.el-table--scrollable-y .el-table__body-wrapper,
  /deep/.el-table__body-wrapper {
    height: 100% !important;
  }
}
.measureSty {
  width: 100%;
  height: 100%;
  /deep/.el-table--scrollable-y .el-table__body-wrapper,
  /deep/.el-table__body-wrapper {
    height: 300px;
  }
  /deep/.el-table__footer-wrapper,
  /deep/.el-table__header-wrapper {
    border-top: 2px solid #909399;
    border-bottom: 2px solid #909399;
  }
  /deep/.el-table td.el-table__cell,
  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
    padding: 4px 0;
  }
  .measureInfo {
    width: 100%;
    > :first-child {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin: 20px 0 30px;
    }
    > :last-child {
      display: flex;
      padding: 0 10px;
      flex-wrap: wrap;
      > span {
        width: calc(100% / 3);
        margin: 7px 0;
        :nth-child(4),
        :nth-child(5) {
          margin-bottom: 14px;
        }
      }
    }
  }
}
.radioSty {
  width: 91%;
  height: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > span {
    width: 82px;
    text-align: end;
  }
}
// 出生日期
.pickerSty {
  /deep/.el-input__icon {
    line-height: 32px;
  }
}
</style>
